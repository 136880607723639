<template>
  <div class="view doodle">
    <div class="box">
      <div class="box-rect">
        <img src="/img/icons/favicon-32x32.png" class="box-logo">

        <div class="box-text__wrapper">
          <div class="box-text">
            Doodle for Dollars
          </div>

          <div
            class="box-button box-button_short"
            @click="$router.push({ path:`/map` })"
          >
            <span>Learn more</span>
            <Arrow class="box-arrow_black" />
          </div>
        </div>
      </div>
    </div>

    <div class="box-wrapper">
      <!-- first three ranks -->
      <div class="box-wrapper__top">
        <RankBox
          v-for="(x, i) in topCharities"
          :key="x.id"
          :charityId="x.id"
          :rank="i+1"
          :title="x.name"
          :artworks="x.artworks_count"
        />
      </div>

      <!-- list of other ranks -->
      <div class="box-wrapper__other">
        <div class="box-list">
          <!-- label -->
          <div class="box-list__label">
            <BoxListItem
              :place="'place'"
              :artworks="'artworks'"
              :title="'non profit'"
              :isLabel="true"
            />
          </div>

          <!-- list of items -->
          <div class="box-list__ranks">
            <BoxListItem
              v-for="(x, i) in otherCharities"
              :key="x.id"
              :charityId="x.id"
              :place="i+4"
              :artworks="x.artworks_count"
              :title="x.name"
            />
          </div>
        </div>

        <!-- button -->
        <div
          class="box-button"
          @click="startSharing"
        >
          <span>Start Doodling for Non Profits Today!</span>
          <Arrow class="box-arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/Arrow.svg';
import RankBox from '@/components/RankBox.vue'
import BoxListItem from '@/components/BoxListItem.vue'
import axios from 'axios'
import { mapState, mapMutations } from 'vuex'
  
export default {
  components: {
    Arrow,
    RankBox,
    BoxListItem
  },
  data() {
    return {
      charities: [],
      topCharities: [],
      otherCharities: []
    }
  },
  computed: {
    ...mapState([
      'loading',
      'howToModal'
    ])
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'openFormModal',
      'closeHowToModal'
    ]),

    startSharing: function () {
      this.closeHowToModal()
      this.openFormModal()
    },

    closeModal: function () {
      this.closeHowToModal()
    },

  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/charity?per_page=48`)
      .then((res) => {
        this.charities = res.data.data

        if (this.charities) {
          const TOP_CHARITIES_LIMIT = 3
          //const OTHER_CHARITIES_LIMIT = 8

          this.topCharities = this.charities.filter((_, index) => index < TOP_CHARITIES_LIMIT)
          this.otherCharities = this.charities.filter((_, index) => index >= TOP_CHARITIES_LIMIT)
        }
      })
      .catch((err) => console.log(err))
  }
}
</script>
